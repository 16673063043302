import {Switch, Routes, Route, HashRouter, BrowserRouter} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {Provider, useSelector} from "react-redux";
import { Navigate } from 'react-router-dom'
import configureAppStore, {dispatchToStore} from "./store";
import {Container} from "@mui/material";
import {useEffect} from "react";

import TitleBar from "./components/TitleBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import GatewayListPage from "./pages/gateway/GatewayListPage";
import LoginPage from "./pages/user/LoginPage";
import LogoutPage from "./pages/user/LogoutPage";
import RegisterPage from "./pages/user/RegisterPage";
import UserAccountPage from "./pages/user/UserAccount";
import AnotherPage from "./pages/AnotherPage";
import GatewaySettingsPage from "./pages/gateway/GatewaySettingsPage";

import {loginLoad} from "./store/slices/userLogin";
import CalculatorPage from "./pages/CalculatorPage";
import GlobalSettingsPage from "./pages/gateway/GlobalSettingsPage";
import NodeListPage from "./pages/gateway/NodeListPage";
import SensorListPage from "./pages/gateway/SensorListPage";


export const history = createBrowserHistory();

/*
const PrivateRoute = ({ isLoggedIn, ...props }) =>
        isLoggedIn ? <Route { ...props } /> : <Redirect to="/login" />
*/

// https://ui.dev/react-router-protected-routes-authentication/

function PrivateRoute({isLoggedIn, element, path }) {
    const { authed } = useAuth();
    const ele = isLoggedIn === true
        ? element
        : <Navigate to="/login" replace />;

    return <Route path={path} element={ele} />;
}

function LoadLogin(){
    const userLogin = useSelector(state => state.userLogin)

    useEffect(() => {
        //
        dispatchToStore(loginLoad())
    }, []);

    //let location = useLocation();

    return (<></>)
}

function RequireAuth({children }: { children: JSX.Element }) {
    const userLogin = useSelector(state => state.userLogin)
    const loggedIn = userLogin.loggedIn
    const isLoaded = userLogin.isLoaded

    if (!isLoaded){
        return (<></>)
    }
    if (!loggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" />;
    }

    return children;
}


function App() {


    return (
        <Provider store={configureAppStore()}>
            <HashRouter>
                <LoadLogin />

                <TitleBar />

                <Container maxWidth="xl" sx={{padding: 4}}>
                    <Routes>
                        <Route exact path='/' element={<HomePage />} />

                        <Route path='/login' element={<LoginPage />} />
                        <Route path='/logout' element={<LogoutPage />} />
                        <Route path='/register' element={<RegisterPage />} />
                        <Route path='/user_account' element={<RequireAuth> <UserAccountPage /> </RequireAuth>} />


                        <Route path="/calculator" element={<RequireAuth> <CalculatorPage /> </RequireAuth>} />

                        <Route path="/other" element={<RequireAuth> <AnotherPage /> </RequireAuth>} />
                        <Route path="/gateways" element={<RequireAuth> <GatewayListPage /> </RequireAuth>} />
                        <Route path="/gateways/:gid/settings/view" element={<RequireAuth> <GlobalSettingsPage /> </RequireAuth>} />
                        <Route path="/gateways/:gid/settings" element={<RequireAuth> <GatewaySettingsPage /> </RequireAuth>} />

                        <Route path="/gateways/:gid/nodes/view" element={<RequireAuth> <NodeListPage /> </RequireAuth>} />
                        <Route path="/gateways/:gid/nodes/:nid/sensors/list" element={<RequireAuth> <SensorListPage /> </RequireAuth>} />

                    </Routes>
                </Container>
                <Footer />
            </HashRouter>
        </Provider>
    );
}

export default App;
