import {useParams} from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Container,
    IconButton,
    Paper,
    Stack, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Typography, useTheme
} from "@mui/material";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {dispatchToStore} from "../../store";
import {deleteNodeFromNodeList, loadNodeList, updateNodeListProp} from "../../store/slices/nodeList";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import Switch from '@mui/material/Switch';
import {loadGatewayList} from "../../store/slices/gatewayList";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SettingsIcon from "@mui/icons-material/Settings";
import {GatewayMode} from "../../components/GatewayMode";
import VisibilityIcon from "@mui/icons-material/Visibility";

function IsPresent(props){
    const theme = useTheme();
    if (props.present){
        return(<EventAvailableIcon style={{color: theme.status.present}}/>)
    } else {
        return(<EventBusyIcon style={{color: theme.status.not_present}}/>)
    }
}

function IsConnected(props){
    const theme = useTheme();
    if (props.connected){
        return(<BluetoothIcon style={{color: theme.status.connected}}/>)
    } else {
        return(<BluetoothDisabledIcon style={{color: theme.status.not_connected}}/>)
    }
}

function EnabledSwitch(props){

    function handleChange(){
        dispatchToStore(updateNodeListProp({
            gid: props.gid,
            nid: props.nid,
            prop: "auto_connect",
            value: !props.enabled}
        ))
    }

    if (props.applied) {
        return (
            <Switch
                disabled={props.disabled}
                checked={props.enabled}
                onChange={handleChange}
            />
        )
    } else {
        return (
            <CircularProgress />
        )
    }
}

function NodeListNavigation(props){

    let navigate = useNavigate();

    const gateways = useSelector(state => state.gatewayList.items)

    const gateway = gateways[props.gid]

    useEffect(() => {
        dispatchToStore(loadGatewayList())
        return ()=>{
            // on page leave
        }

    }, []);

    if (gateway === undefined){
        return <></>
    }

    function navigateTo(){
        const target = "/gateways/"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    return (
        <Stack direction={"column"}>

            <Stack direction={"row"} justifyContent={"space-between"} >
                <Typography variant={"subtitle1"}>{gateway.description}</Typography>
                <GatewayMode gid={gateway.gid} />
                <IconButton  onClick={(e)=>{navigateTo()}} >
                    <ArrowBackIcon />
                </IconButton>
            </Stack>

            <Stack direction={"row"} justifyContent={"center"} >
                <Typography variant={"h5"}>{props.topic}</Typography>
            </Stack>

        </Stack>
    )
}

export default function NodeListPage() {

    const { gid } = useParams()

    let navigate = useNavigate();

    const nodeList = useSelector(state => state.nodeList)

    const gateways = useSelector(state => state.gatewayList.items)
    const gateway = gateways[gid]

    useEffect(() => {
        // on page load
        console.log("pageLoad")
        update()
        const interval = setInterval(()=>{update()}, 1000)

        return ()=>{
            // on page leave
            console.log("pageUnload ", interval)
            clearInterval(interval)
        }

    }, []);

    function update(){
        dispatchToStore(loadNodeList({gid:gid}))
        dispatchToStore(loadGatewayList())
    }

    function create_link(device_id, node_id){
        const target = "/gateways/" + device_id + "/nodes/" + node_id + "/sensors" + "/list"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    function delete_node(gid, nid){
        dispatchToStore(deleteNodeFromNodeList({
                gid: gid,
                nid: nid,
            }
        ))
    }

    if (gateway === undefined){
        return <></>
    }

    const isBusy = nodeList.busy || !gateway.config_mode;

    return (
        <Container>
            <Card style={{backgroundColor: "#c5c5c5"}}>
                <CardContent>
                    <NodeListNavigation gid={gid} topic={"Node List"}/>

                    <Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>MAC</TableCell>
                                        <TableCell align="center">Enabled</TableCell>
                                        {!gateway.config_mode && <TableCell align="center">Connected</TableCell>}
                                        <TableCell align="center">Last Contact</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    { nodeList.gid===gid && Object.entries(nodeList.items).map(([key, node]) => { return (
                                        <TableRow key={key} >
                                            <TableCell>{node.mac}</TableCell>
                                            <TableCell align="center"><EnabledSwitch gid={node.gid} nid={node.nid} enabled={node.enabled} applied={node.settings_applied} disabled={isBusy}/></TableCell>
                                            {!gateway.config_mode && <TableCell align="center"><IsConnected connected={node.connected}/></TableCell> }
                                            <TableCell align="center">{node.last_contact}</TableCell>
                                            <TableCell align="center">
                                                <Button color="primary" onClick={(e)=>{create_link(gid, key)}} >
                                                    { gateway.config_mode ?
                                                        <SettingsIcon />
                                                        :
                                                        <VisibilityIcon />
                                                    }
                                                </Button>

                                                <Button color="primary" onClick={(e)=>{delete_node(node.gid, node.nid)}} disabled={isBusy}>
                                                    <DeleteForeverIcon />
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    ) } )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Box>

                </CardContent>
            </Card>
        </Container>
    );

};
