import {useParams} from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Container, FormControl,
    IconButton, InputLabel,
    Paper, Select, SelectChangeEvent,
    Stack, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Typography, useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {dispatchToStore} from "../../store";
import {deleteNodeFromNodeList, loadNodeList, updateNodeListProp} from "../../store/slices/nodeList";
import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import {loadGatewayList} from "../../store/slices/gatewayList";
import {loadSensorList, updateSensorList} from "../../store/slices/sensorList";
import MenuItem from "@mui/material/MenuItem";
import {GatewayMode} from "../../components/GatewayMode";


const sensorListLookup = {
    "Accelerometer": {
        "dataSizeInBytes": (3*4) + 1,
        "sampleTimes": {80: "80ms", 40: "40ms", 20: "20ms", 10: "10ms", 5: "5ms", 2.5: "2.5ms", 1.25: "1.25ms"}
    },

    "Gyroscope": {
        "dataSizeInBytes": (3*4) + 1,
        "sampleTimes": {40: "40ms", 20: "20ms", 10: "10ms", 5: "5ms", 2.5: "2.5ms", 1.25: "1.25ms" }
    },

    "Magnetometer": {
        "dataSizeInBytes": (3*4) + 1,
        "sampleTimes": {500: "500ms", 100: "100ms", 50: "50ms", 33.3: "33.3ms", 20: "20ms", 10: "10ms", 6.67: "6.67ms", 5: "5ms" }
    },

    "Ambient Pressure": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {10000: "10s", 5000: "5s", 1000: "1s", 500: "500ms", 250: "250ms", 100: "100ms", 50: "50ms", 20: "20ms" }
    },

    "Thermometer": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {10000: "10s", 5000: "5s", 1000: "1s", 500: "500ms", 250: "250ms", 100: "100ms", 50: "50ms", 20: "20ms" }
    },

    "Relative Humidity Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {10000: "10s", 5000: "5s", 1000: "1s", 500: "500ms", 250: "250ms", 100: "100ms", 50: "50ms", 20: "20ms" }
    },

    "CO2 Concentration Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000: "60s", 10000: "10s", 1000: "1s" }
    },

    "Gas ECO2/BVOC/IAQ": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000: "60s", 10000: "10s", 1000: "1s" }
    },

    "TVOC Concentration Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000: "60s", 10000: "10s", 1000: "1s" }
    },

    "Ambient Light Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000: "60s", 10000: "10s", 1000: "1s", 800: "800ms", 500: "500ms", 250: "250ms", 125: "125ms" }
    },
    "Barometer": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000: "60s", 10000: "10s", 1000: "1s" }
    }
}

function getSampleTimes(sensor){
    if (Object.keys(sensorListLookup).includes(sensor)){
        return sensorListLookup[sensor].sampleTimes
    } else {
        return []
    }
}

function getSampleTimeStr(sensor, sampleTime){
    if (Object.keys(sensorListLookup).includes(sensor)) {
        if (Object.keys(sensorListLookup[sensor].sampleTimes).includes(sampleTime)) {
            return sensorListLookup[sensor].sampleTimes[sampleTime]
        }
    }
    return 0
}

export function getSampleTime(sensor, sampleTime){
    if (Object.keys(sensorListLookup).includes(sensor)) {
        if (Object.keys(sensorListLookup[sensor].sampleTimes).includes(sampleTime)) {
            return sensorListLookup[sensor].sampleTimes[sampleTime]
        }
    }
    return 0
}

export function SampleTimeSelector(prop) {

    const [sampleTime, setSampleTime] = useState(String(prop.sample_period))

    const handleChange = (event: SelectChangeEvent) => {
        const sampleTime = event.target.value
        console.debug("selected", sampleTime)
        setSampleTime(sampleTime)
        prop.onHandleChange(prop.id, parseInt(sampleTime))
    };

    const validate = s => {

        if (Object.keys(sensorListLookup).includes(prop.sensor)) {
            if (Object.keys(sensorListLookup[prop.sensor].sampleTimes).includes(s)) {
                console.debug("validation success", s)
                return s
            }
        }
        console.debug("validation failed", s)
        return "None"
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="update_time_select">Sample Time</InputLabel>
            <Select
                disabled={prop.disabled}
                value={validate(sampleTime)}
                label={"update_time"}
                onChange={handleChange}

            >
                <MenuItem value={"None"}>None</MenuItem>

                {Object.entries(getSampleTimes(prop.sensor)).map(([st, str]) =>
                    <MenuItem value={st} key={st}>{str}</MenuItem>
                )}
            </Select>
        </FormControl>
    )
}


function EnabledSwitch(props){

    return (
        <Switch
            disabled={props.disabled}
            checked={props.enabled}
            onChange={props.onChange}
        />
    )

}

function SensorListNavigation(props){

    let navigate = useNavigate();

    const gateways = useSelector(state => state.gatewayList.items)
    const nodeList = useSelector(state => state.nodeList.items)

    const gateway = gateways[props.gid]
    const node = nodeList[props.nid]

    useEffect(() => {

        console.info("start init")

        console.debug("found url", "gid", props.gid, "nid", props.nid)

        dispatchToStore(loadGatewayList())
        dispatchToStore(loadNodeList({gid:props.gid}))

        console.info("finished init")

        return ()=>{
            // on page leave
        }

    }, []);

    if (gateway === undefined){
        return (<></>)
    }
    if (node === undefined){
        return (<></>)
    }

    function navigateTo(){
        const target = "/gateways/" + props.gid + "/nodes" + "/view"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    return (
        <Stack direction={"column"}>

            <Stack direction={"row"} justifyContent={"space-between"} >
                <Stack direction={"column"}>
                    <Typography variant={"subtitle1"}>{gateway.description}</Typography>
                    <Typography variant={"subtitle1"}>{node.mac}</Typography>
                </Stack>
                <GatewayMode gid={gateway.gid} />
                <IconButton  onClick={(e)=>{navigateTo()}} >
                    <ArrowBackIcon />
                </IconButton>
            </Stack>

            <Stack direction={"row"} justifyContent={"center"} >
                <Typography variant={"h5"}>{props.topic}</Typography>
            </Stack>

        </Stack>
    )
}

export default function SensorListPage() {

    const { gid, nid } = useParams()

    let navigate = useNavigate();

    const nodeList = useSelector(state => state.nodeList)

    const sensorList = useSelector(state => state.sensorList)

    const gateways = useSelector(state => state.gatewayList.items)
    const gateway = gateways[gid]

    const [sensors, setSensors] = useState(null)
    const [modified, setModified] = useState(false)

    useEffect(() => {
        // on page load
        dispatchToStore(loadSensorList({gid:gid, nid:nid}))
        dispatchToStore(loadGatewayList())
        return ()=>{
        }

    }, []);

    useEffect(() => {
        if (!sensorList.loaded){
            return
        }

        if ((sensorList.gid !== gid) || (sensorList.nid !== nid)){
            return
        }

        if (sensors === null){
            setSensors({...sensorList.items})
        }

        return ()=>{
        }

    }, [sensorList, sensors]);

    if (sensors === null){
        return <></>
    }

    function onChangeEnable(name, newValue){
        console.debug("change", name, newValue)
        const s = {...sensors}
        s[name].auto_connect = newValue
        setSensors(s)

        setModified(true)
    }

    function onHandleChange(sensor_name, sample_time){

        console.debug("change", sensor_name, sample_time)
        const s = {...sensors}

        s[sensor_name].sample_period =  sample_time
        setSensors(s)

        setModified(true)

    }

    function navigateTo(gid){
        const target = "/gateways/" + gid + "/nodes" + "/view"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }


    function onHandleApply(){
        console.debug(sensors)

        dispatchToStore(updateSensorList({
            gid: gid,
            nid: nid,
            sensors: sensors,
        }))

        navigateTo(gid, nid)
    }

    if (gateway === undefined){
        return <></>
    }

    const isBusy = nodeList.busy || !gateway.config_mode;

    return (
        <Container>
            <Card style={{backgroundColor: "#c5c5c5"}}>
                <CardContent>
                    <SensorListNavigation gid={gid} nid={nid} topic={"Sensor List"}/>

                    {modified && <Button onClick={()=>{onHandleApply()}}>
                        Apply Changes
                    </Button>}

                    <Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="center">Enabled</TableCell>
                                        <TableCell align="center">Sample Period</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    { Object.entries(sensors).map(([sensor_name, sensor]) => { return (
                                        <TableRow key={sensor_name} >
                                            <TableCell>{sensor_name}</TableCell>

                                            <TableCell align="center">
                                                <EnabledSwitch
                                                    enabled={sensor.auto_connect}
                                                    disabled={isBusy}
                                                    onChange={()=>{
                                                        onChangeEnable(sensor_name, !sensor.auto_connect)}} />
                                            </TableCell>

                                            <TableCell align="center">
                                                <SampleTimeSelector
                                                    id={sensor_name}
                                                    disabled={isBusy}
                                                    sensor={sensor_name}
                                                    sample_period={sensor.sample_period}

                                                    onHandleChange={onHandleChange}
                                                />

                                            </TableCell>
                                        </TableRow>
                                    ) } )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Box>

                </CardContent>
            </Card>
        </Container>
    );

};
