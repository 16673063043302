import { all, takeLatest, takeEvery } from 'redux-saga/effects'

import {loginUser, loginLoad, logoutUser} from '../slices/userLogin'
import {loadLogin, userLogin, userLogout} from './userLogin'

import {loadGatewayList, updateGatewayListMode} from "../slices/gatewayList";
import {gatewayListLoad, gatewayListUpdateMode} from './gatewayList'

import {deleteNodeFromNodeList, deleteNodeFromNodeListDone, loadNodeList, updateNodeListProp} from "../slices/nodeList";
import {nodeListDeleteNode, nodeListLoad, nodeListUpdateProp} from "./nodeList"

import {loadSensorList, updateSensorList} from "../slices/sensorList";
import {sensorListLoad, sensorListUpdate} from "./sensorList";

import {requestGetGateway} from "../slices/selectedGateway";
import {getGatewayRequest} from "./selectedGateway";

import {loadNode} from "../slices/node";
import {nodeLoad} from "./node";


export default function* rootSaga() {
    return yield all([
        takeEvery(loginUser.type, userLogin),
        takeEvery(loginLoad.type, loadLogin),
        takeEvery(logoutUser.type, userLogout),

        takeEvery(requestGetGateway.type, getGatewayRequest),
        takeEvery(loadGatewayList.type, gatewayListLoad),
        takeEvery(updateGatewayListMode.type, gatewayListUpdateMode),

        takeEvery(loadNodeList.type, nodeListLoad),
        takeEvery(updateNodeListProp.type, nodeListUpdateProp),
        takeEvery(deleteNodeFromNodeList.type, nodeListDeleteNode),

        takeEvery(loadSensorList.type, sensorListLoad),
        takeEvery(updateSensorList.type, sensorListUpdate),

        takeEvery(loadNode.type, nodeLoad),



    ])
}