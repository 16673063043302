import { createSlice } from '@reduxjs/toolkit'

export const nodesSlice = createSlice({
    name: 'nodeList',
    initialState: {
        busy: false,
        items: {},
        gid: -1,
    },
    reducers: {
        loadNodeList: (state,{payload}) => {
            state.gid = payload.gid

            Object.entries(state.items).forEach(([id,node])=> {
                node.loaded = false
            })
        },

        loadedNodeList: (state, {payload})=>{
            let busy = false
            Object.entries(payload).forEach(([id,node])=>{

                // add node if not existing
                if (!(node.nid in state.items)){
                    state.items[node.nid] = {
                        gid: node.gid,
                        nid: node.nid,
                        loaded: false,
                    }
                }

                //
                state.items[node.nid].gid = node.gid
                state.items[node.nid].nid = node.nid
                state.items[node.nid].mac = node.mac

                state.items[node.nid].settings_applied = node.settings_applied

                state.items[node.nid].enabled = node.enabled
                state.items[node.nid].connected = node.connected

                state.items[node.nid].enabled_sensor_count = node.enabled_sensor_count

                state.items[node.nid].last_contact = node.last_contact

                state.items[node.nid].loaded = true

                if (node.settings_applied === 0){
                    busy = true
                }
            })

            const deleteIds = []
            Object.entries(state.items).forEach(([id,node])=> {
                if (!node.loaded){
                    deleteIds.push(id)
                }
            })

            Object.entries(deleteIds).forEach(([k, id])=> {
                delete state.items[id]
            })

            state.busy = busy
        },

        updateNodeListProp: (state, {payload}) => {
        },

        updateNodeListPropDone: (state, {payload}) => {
        },

        deleteNodeFromNodeList: (state, {payload}) => {
        },

        deleteNodeFromNodeListDone: (state, {payload}) => {
        },

    }
})

export const {
    loadNodeList, loadedNodeList,
    updateNodeListProp, updateNodeListPropDone,
    deleteNodeFromNodeList, deleteNodeFromNodeListDone,


} = nodesSlice.actions

export default nodesSlice.reducer
