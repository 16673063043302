import { put } from 'redux-saga/effects';
import {recaderoRequest} from "./recaderoApi";
import {deleteNodeFromNodeListDone, loadedNodeList, updateNodeListPropDone} from "../slices/nodeList";
import {act} from "react-dom/cjs/react-dom-test-utils.production.min";


export function * nodeListLoad(action){

    const requestData = {"gateway_id": action.payload.gid}

    // execute api request
    const response = yield recaderoRequest("node", "list", requestData)

    if (response){
        yield put(loadedNodeList(response))
    } else {
        yield put(loadedNodeList({"none":"empty"}))
    }

}

export function * nodeListUpdateProp(action){

    const requestData = {
        "gateway_id": action.payload.gid,
        "node_id": action.payload.nid,
        "prop": action.payload.prop,
        "value": action.payload.value
    }

    // execute api request
    const response = yield recaderoRequest("node", "update-prop", requestData)

    if (response){
        yield put(updateNodeListPropDone(response))
    } else {
        yield put(updateNodeListPropDone(response))
    }

}

export function * nodeListDeleteNode(action){

    const requestData = {
        "gateway_id": action.payload.gid,
        "node_id": action.payload.nid,
    }
    console.log("delete")
    // execute api request
    const response = yield recaderoRequest("node", "delete-node", requestData)

    if (response){
        yield put(deleteNodeFromNodeListDone(response))
    } else {
        yield put(deleteNodeFromNodeListDone(response))
    }

}