import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import {dispatchToStore} from "../store";
import {loadGatewayList} from "../store/slices/gatewayList";
import {Stack} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";


export function GatewayMode(props){

    const gateways = useSelector(state => state.gatewayList.items)
    const gateway = gateways[props.gid]

    useEffect(() => {
        if (gateway === undefined) {
            dispatchToStore(loadGatewayList())
        }
        return ()=>{
            // on page leave
        }

    }, [gateway]);

    if (gateway === undefined){
        return <></>
    }

    if (gateway.config_mode){
        return <Stack direction={"row"}><SettingsIcon /> Config Mode</Stack>

    } else {
        return <Stack direction={"row"}><PlayCircleOutlineIcon /> Run Mode</Stack>
    }
}