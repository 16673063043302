import React from 'react';
import {Card, CardContent} from "@mui/material";
import {Link} from "react-router-dom";


export default function HomePage() {

    return (
        <Card>
            <CardContent>
                <h1 >This is the public welcome page</h1>

                some text here and there

            </CardContent>
        </Card>
    );

};

