import { createSlice } from '@reduxjs/toolkit'

export const gatewayListSlice = createSlice({
    name: 'gatewayList',
    initialState: {
        items: {},
    },
    reducers: {
        loadGatewayList: (state,{payload}) => {
        },

        loadedGatewayList: (state, {payload})=>{
            Object.entries(payload).forEach(([id,device])=>{
                // create if non-existent
                if (!(device.gid in state.items)){
                    state.items[device.gid] = {
                        gid: device.gid,
                        serial_number: "00000000",
                        description: "unknown",
                        connected: false,
                        config_mode: false,
                        settings_applied: false,
                        last_contact: -1,
                    }
                }
/*
                state.items[device.gid].name = device.name
                state.items[device.gid].last_modified = device.last_modified
                state.items[device.gid].serial_number = device.serial_number
                */
                if (state.items[device.gid].description !== device.description){
                    state.items[device.gid].description = device.description
                }
                if (state.items[device.gid].connected !== device.connected){
                    state.items[device.gid].connected = device.connected
                }
                if (state.items[device.gid].config_mode !== device.config_mode){
                    state.items[device.gid].config_mode = device.config_mode
                }
                if (state.items[device.gid].settings_applied !== device.settings_applied){
                    state.items[device.gid].settings_applied = device.settings_applied
                }
                if (state.items[device.gid].last_contact !== device.last_contact){
                    state.items[device.gid].last_contact = device.last_contact
                }
                if (state.items[device.gid].serial_number !== device.sn){
                    state.items[device.gid].serial_number = device.sn
                }

            })

        },

        updateGatewayListMode: (state, {payload}) => {
        },
        updateGatewayListModeDone: (state, {payload}) => {
        },
    }
})

export const {
    loadGatewayList, loadedGatewayList,
    updateGatewayListMode, updateGatewayListModeDone,

} = gatewayListSlice.actions

export default gatewayListSlice.reducer
