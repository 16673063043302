
import {config} from "../../config";

const user_session_key = "user_session"

export function * recaderoRequest(requestType, actionType, requestData){

    let request
    if (requestType === "user" && actionType === "login"){
        request = {
            auth: {
                user_name: requestData.user_name,
                user_pass: requestData.user_pass,
            },

            request: requestType,

            action: actionType,

            data: requestData,
        }

    } else {
        request = {
            auth: {
                session: loadSessionId()
            },

            request: requestType,

            action: actionType,

            data: requestData,
        }
    }

    console.debug("request", request)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };

    const response = yield fetch(`${config.apiUrl}`, requestOptions)

    // get data from response stream
    const response_text = yield response.text()

    console.debug("response", response_text)
    if (!response.ok){
        // do something on error
        console.error("recadero api login failed")
        console.log(response.status)
        console.log(response_text)
        return false
    }

    try {
        // decode
        const json_response = JSON.parse(response_text);

        // update session
        storeSessionId(json_response[actionType]["session"])

        return json_response[actionType]["data"]
    } catch (e) {
        console.log(e)
        console.log(response_text)
    }

    return false
}

export function clearSessionId(){
    window.localStorage.removeItem(user_session_key)
    console.log("session cleared")
}

function loadSessionId(){
    try {
        const sessionId = JSON.parse(window.localStorage.getItem(user_session_key))

        if (sessionId === undefined || sessionId === null || sessionId === ""){
            console.log("no session id found in store")
        } else {
            return sessionId;
        }
    } catch (e){

    }
    return ""
}

function storeSessionId(sessionId){

    if (sessionId !== undefined && sessionId !== ""){
        try {
            const value = JSON.stringify(sessionId)
            window.localStorage.setItem(user_session_key, value)
        } catch (e) {

        }
    }
}